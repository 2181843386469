export default {
  name: "system-maintenance",
  data() {
    return {
      innerPages: [
        {
          name: "Clients and Physicians",
          path: "/system-maintenance/clients-physicians",
          icon: "mdi-account-multiple-outline",
        },
        {
          name: "Facilities",
          path: "/system-maintenance/facilities",
          icon: "mdi-list-box-outline",
        },
        {
          name: "Payors",
          path: "/system-maintenance/payors",
          icon: "mdi-text-account",
        },
        {
          name: "CPT Codes",
          path: "/system-maintenance/cpt-codes",
          icon: "mdi-medication-outline",
        },
        {
          name: "Diagnosis Codes",
          path: "/system-maintenance/diagnosis-codes",
          icon: "mdi-doctor",
        },
        {
          name: "Fee Schedules",
          path: "/system-maintenance/fee-schedule",
          icon: "mdi-cash-clock",
        },
        {
          name: "Accounts",
          path: "/system-maintenance/accounts",
          icon: "mdi-account-box-outline",
        },
        {
          name: "Revenue Codes",
          path: "/system-maintenance/revenue-codes",
          icon: "mdi-barcode-scan",
        },
      ],
    };
  },
};
